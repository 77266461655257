import styled from "styled-components"

import { HomeGroupAddress } from "src/components/HomeGroups/HomeGroupAddress"
import { HomeGroupHomes } from "src/components/HomeGroups/HomeGroupHomes"
import { HomeGroupMembers } from "src/components/HomeGroups/HomeGroupMembers"
import {
  useFetchHomeGroup,
  usePatchHomeGroup,
} from "src/data/homeGroups/queries/homeGroupQueries"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { getAccessLogic } from "src/data/user/logic/accessLogic"
import { useFlags } from "src/hooks/useFlags"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { BackButton } from "src/ui/BackButton/BackButton"
import { Editable } from "src/ui/Editable/Editable"
import { MainView } from "src/ui/Layout/MainView"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { spacing } from "src/ui/spacing"
import { ITab } from "src/ui/Tabs/Tab"
import { TabPanel } from "src/ui/Tabs/TabPanel"
import { Tabs } from "src/ui/Tabs/Tabs"

export function HomeGroupDetails({ homeGroupId }: { homeGroupId: string }) {
  const { t, langKeys } = useTranslate()
  const { org } = useOrganization()
  const patchHomeGroup = usePatchHomeGroup()
  const { alpha } = useFlags()

  const userCanChangeName = getAccessLogic({
    role: org.user_role,
  }).hasAdminAccess

  function onTitleSave(name: string) {
    patchHomeGroup.mutate({ orgId: org.id, homeGroupId, body: { name } })
  }

  const fetchHomeGroup = useFetchHomeGroup({ orgId: org.id, homeGroupId })
  const isLoading = fetchHomeGroup.isInitialLoading
  const homeGroup = fetchHomeGroup.data
  const name = homeGroup?.name

  const tabs: ITab[] = [
    {
      id: Routes.HomeGroupDetails.tabs[0],
      label: t(langKeys.home_plural),
      view: !!homeGroupId ? <HomeGroupHomes id={homeGroupId} /> : null,
    },
    {
      id: Routes.HomeGroupDetails.tabs[1],
      label: t(langKeys.organizations_homegroup_access_title),
      view: !!homeGroupId ? (
        <HomeGroupMembers
          homeGroupId={homeGroupId}
          homeGroupRole={homeGroup?.user_role}
        />
      ) : null,
      placeholder: <MSkeleton variant="rect" width="100%" height={200} />,
    },
    {
      id: Routes.HomeGroupDetails.tabs[2],
      label: t(langKeys.address),
      view: !!homeGroupId ? (
        <HomeGroupAddress
          homeGroupId={homeGroupId}
          homeGroupAddress={homeGroup?.address}
          homeGroupLocation={homeGroup?.location}
          homeGroupRole={homeGroup?.user_role}
        />
      ) : null,
      placeholder: <MSkeleton variant="rect" width="100%" height={300} />,
      hidden: !alpha,
    },
  ].filter((tab) => !tab.hidden)

  if (fetchHomeGroup.isError) {
    return (
      <MBanner type="error">{t(langKeys.failed_general_error_title)}</MBanner>
    )
  }

  return (
    <MainView
      title={
        isLoading ? (
          <MSkeleton width={350} height={48} />
        ) : (
          <Editable onSave={onTitleSave} disabled={!userCanChangeName}>
            {name}
          </Editable>
        )
      }
      metaTitle={name}
      titleBarProps={{ bottomMargin: spacing.L }}
      banner={
        <BackButton forceLinkNavigation to={Routes.HomeGroups.location()}>
          {t(langKeys.back)}
        </BackButton>
      }
    >
      <Tabs labels={tabs}>
        {tabs.map((tab) => (
          <StyledTabPanel key={tab.id}>
            {isLoading ? (tab.placeholder ?? null) : tab.view}
          </StyledTabPanel>
        ))}
      </Tabs>
    </MainView>
  )
}

const StyledTabPanel = styled(TabPanel)`
  margin-top: ${spacing.XL2};
`
